<template>
  <div class="cursor-pointer mb-7 mr-7">
    <svg
      version="1.1"
      id="propertiesRoomBlock"
      :class="{ error: roomError }"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="176px"
      height="109px"
      xml:space="preserve"
      @click="blockClick"
    >
      <path
        v-show="roomError"
        d="M10,0h156c5.5,0,10,4.5,10,10v89c0,5.5-4.5,10-10,10H10c-5.5,0-10-4.5-10-10V10C0,4.5,4.5,0,10,0z"
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="#c53232"
      />
      <path
        id="bg"
        v-show="!roomError"
        d="M10,0h156c5.5,0,10,4.5,10,10v89c0,5.5-4.5,10-10,10H10c-5.5,0-10-4.5-10-10V10C0,4.5,4.5,0,10,0z"
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="#262524"
      />
      <text transform="matrix(1 0 0 1 11 25)" class="st4 st5 ltgrey" font-size="10px">
        {{ roomLabel }}
      </text>
      <text transform="matrix(1 0 0 1 11 50)" class="font-extrabold st1 st2" font-size="24px">
        {{ roomName }}
      </text>
      <text
        v-show="roomOccupied && !roomCleaning"
        transform="matrix(1 0 0 1 11 71)"
        class="st1 st5 st3"
      >
        Occupied
      </text>
      <text
        v-show="!roomOccupied && !roomCleaning"
        transform="matrix(1 0 0 1 12 71)"
        class="st1 st5 st3"
      >
        Vacant
      </text>
      <text v-show="roomCleaning" transform="matrix(1 0 0 1 12 71)" class="st1 st5 st3">
        Cleaning
      </text>

      <text transform="matrix(1 0 0 1 11 95)" class="basetext">
        ONLINE:
      </text>
      <text transform="matrix(1 0 0 1 62.1518 95)" class="green font-extrabold text-12">
        4
      </text>
      <text transform="matrix(1 0 0 1 106 96)" class="basetext">
        OFFLINE:
      </text>
      <text transform="matrix(1 0 0 1 162 96)" class="font-extrabold yellow text-12">
        1
      </text>
      <g v-show="roomError">
        <text transform="matrix(1 0 0 1 118.3378 40)" class="st1 st2 st3">
          {{ zonelineLabels.name }}
        </text>
        <text transform="matrix(1 0 0 1 118.5419 57)" class="st1 st2 st3">
          Offline
        </text>
        <path
          class="st1"
          d="M145,25h12c1.9,0,3.1-2.1,2.1-3.8l-6-11c-1-1.6-3.3-1.6-4.3,0l-6,11C141.9,22.9,143.1,25,145,25z M150,14.1	c0-1.3,2-1.3,2,0c-0.3,0.9,0.9,6.1-1,6C149.2,20.2,150.3,14.9,150,14.1z M151,21c1.3,0,1.3,2,0,2C149.7,23,149.7,21,151,21z"
        />
        <path fill="none" d="M141.5 8H160.5V25H141.5z" />
      </g>
    </svg>
  </div>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        default: '101',
      },
      occupied: {
        type: Boolean,
        default: false,
      },
      cleaning: {
        type: Boolean,
        default: false,
      },
      error: {
        type: Boolean,
        default: false,
      },
      url: {
        type: String,
        default: '/properties/show/building-1/floor-1/room-101',
      },
    },
    components: {},
    data() {
      return {
        roomName: this.name,
        roomOccupied: this.occupied,
        roomCleaning: this.cleaning,
        roomError: this.error,
        roomUrl: this.url,
      };
    },
    computed: {
      zonelineLabels: function() {
        return this.$store.getters.applianceLabels('zoneline');
      },
      roomLabel: function() {
        return this.$store.getters.propertyLabels('room');
      },
    },
    mounted() {},
    watch: {},
    methods: {
      blockClick: function() {
        if (this.name == '101') this.$router.push(this.roomUrl);
      },
    },
  };
</script>
<style lang="scss" scope>
  @import '../styles/typovars';

  svg#propertiesRoomBlock {
    //   .error {
    //     fill: $red;
    //   }
    .st1 {
      fill: #fff;
    }
    .st2 {
      font-family: $sans;
    }
    .st3 {
      font-size: 12px;
    }
    .st4 {
      fill: $ltgrey;
    }
    .st5 {
      font-family: $sans;
    }
    .st8 {
      font-family: $sans;
    }
    .basetext {
      fill: $ltgrey;
      font-size: 12px;
    }
    &.error {
      .basetext,
      .ltgrey {
        fill: $dkgrey;
      }
      .green,
      .yellow {
        fill: $dkgrey !important;
      }
    }
  }
</style>
