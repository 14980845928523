<template>
  <section>
    <propertiesPageHeader
      :name="property.name"
      :address="property.address"
      :units="property.units"
      :applianceCounts="property.applianceCounts"
      :managerEmail="property.managerEmail"
      :managerName="property.managerName"
    ></propertiesPageHeader>
    <div class="gradient_spacer relative w-full m-0"></div>
    <div class="rounded grey p-3 pb-10 ml-5 mr-1">
      <div class="text-ltgrey w-full relative pb-2">
        <h1 class="inline-block font-extrabold text-14">{{ floor.name }}</h1>
        <div class="ml-2 inline-block text-12">{{ floor.units }} {{ roomLabel }}s</div>
        <router-link
          to="/properties/show/building-1"
          tag="a"
          class="float-right block font-extrabold text-14"
          >X</router-link
        >
      </div>
      <ul class="flex flex-wrap flex-row max-main p-5 pb-0">
        <roomlisting name="101" error></roomlisting>
        <roomlisting
          v-for="item in dataArray"
          :name="item.name"
          :occupied="item.occupied"
          :cleaning="item.cleaning"
          :key="item.name"
        ></roomlisting>
      </ul>
    </div>
    <div class="gradient-floor fixed bottom-0 w-full left-0 pointer-events-none"></div>
  </section>
</template>

<script>
  import propertiesPageHeader from '@/components/propertiesPageHeader.vue';
  import roomlisting from '@/components/propertiesRoomBlock.vue';
  import { getDataByHostname } from '../utils/dataUtil';

  export default {
    components: { roomlisting, propertiesPageHeader },

    data() {
      return {
        dataArray: [],
        property: [{ name: '', applianceCounts: {}, buildings: [] }],
      };
    },
    mounted() {
      const dataByHostName = getDataByHostname();
      this.property = dataByHostName?.properties?.[0] || {};

      for (var i = 102; i < 224; i++) {
        this.dataArray.push({
          name: '' + i,
          occupied: Math.random() < 0.5,
          cleaning: Math.random() < 0.15,
        });
      }
    },
    computed: {
      roomLabel: function() {
        return this.$store.getters.propertyLabels('room');
      },
      floor() {
        return this.$store.state.properties[0].buildings[0].floors[0];
      },
    },
    watch: {},
    methods: {},
  };
</script>
<style lang="scss" scope>
  @import '../styles/typovars';
  section {
    padding-bottom: 220px;
  }
  .gradient_spacer {
    border: none;
  }
</style>
